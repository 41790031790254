@charset "UTF-8";

/*!
 * Fella version 0.6.0
 * Copyright Floofi Systems
 */

/* titles.css */
.fella-loader-center {
    margin-left: auto;
    margin-right: auto;
}

.fella-title {
    color: var(--fella-text-main);
    font-weight: 800;
    letter-spacing: -0.066875rem;
    line-height: 3.5rem;
    font-size: 2rem;
}

.fella-title-intro {
    margin-top: 10px;
    margin-bottom: 10px;
}

.fella-title-centered {
    text-align: center;
}

/* sidebar.css */
.fella-sidebar {
    display: none;
}

.fella-sidebar-show .fella-sidebar {
    display: block;
    width: 456px;
    position: fixed;
    top: 0;
    padding-top: 64px;
    left: 0;
    bottom: 0;
    background-color: var(--fella-bg-main);
    border-right: 1px solid var(--fella-border-dim);
    z-index: 5;
    overflow: auto;
}

.fella-sidebar-show .fella-sidebar-next {
    margin-left: 456px;
}

.fella-sidebar-next .fella-container {
    max-width: 920px;
}

.fella-sidebar-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.fella-sidebar-menu-heading, .fella-sidebar-menu-item {
    margin: 0 20px;
    height: 35px;
    display: flex;
    align-items: center;
    padding: 0 20px;
}

.fella-sidebar-menu {
    margin-top: 20px;
    margin-bottom: 50px;
}

.fella-sidebar-menu-heading {
    color: var(--fella-text-secondary);
    margin-top: 20px;
}

.fella-sidebar-menu-item {
    color: var(--fella-text-main);
    text-decoration: none;
    background-color: transparent;
    border-radius: 7px;
}

.fella-sidebar-menu-item:hover, .fella-sidebar-menu-item.fella-sidebar-menu-item-active {
    background-color: var(--fella-bg-secondary-dim);
}

.fella-sidebar-menu-item.fella-sidebar-menu-item-active {
    pointer-events: none;
}

.fella-sidebar-submenu .fella-sidebar-menu-item {
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.fella-sidebar-button {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 16px;
    left: 16px;
    opacity: .75;
    background-color: var(--fella-bg-main);
    border-radius: 999px;
    cursor: pointer;
    z-index: 10;
}

.fella-sidebar-button:hover {
    opacity: .95;
}

.fella-sidebar-button-hide {
    display: none;
}

.fella-sidebar-show .fella-sidebar-button-hide {
    display: initial;
}

.fella-sidebar-show .fella-sidebar-button-show {
    display: none;
}

.fella-sidebar-submenu > li {
    margin-left: 38px;
    padding-left: 0;
    border-left: 2px solid var(--fella-border);
}

/* sidebar-mobile.css */
@media (max-width: 1100px) {
    .fella-sidebar-show .fella-sidebar {
        display: none !important;
    }

    .fella-sidebar-show .fella-sidebar-next {
        margin-left: 0 !important;
    }

    .fella-sidebar-show-mobile .fella-sidebar {
        width: 100% !important;
        display: block !important;
    }

    body:has(.fella-sidebar-show-mobile) {
        overflow: hidden !important;
    }

    .fella-sidebar-button-hide {
        display: none !important;
    }

    .fella-sidebar-button-show {
        display: initial !important;
    }

    .fella-sidebar-show-mobile .fella-sidebar-button-hide {
        display: initial !important;
    }

    .fella-sidebar-show-mobile .fella-sidebar-button-show {
        display: none !important;
    }
}

/* pagination.css */
.fella-pagination-container {
    display: flex;
    justify-content: space-between;
}

.fella-pagination-left {
    padding-right: 8px;
    padding-left: 28px;
    cursor: pointer;
}

.fella-pagination-right {
    padding-left: 8px;
    padding-right: 28px;
    justify-self: end;
    cursor: pointer;
}

.fella-pagination-header {
    margin-bottom: 2px;
    font-weight: 400;
    line-height: 1.125rem;
    font-size: 0.8125rem;
    color: var(--fella-text-secondary);
}

.fella-pagination-inner {
    position: relative;
    display: flex;
}

.fella-pagination-description {
    color: var(--fella-text-main);
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
}

.fella-pagination-right-icon {
    position: absolute;
    margin-top: 2px;
    right: -26px;
    color: var(--fella-text-secondary);
}

.fella-pagination-left-icon {
    position: absolute;
    margin-top: 2px;
    left: -26px;
    color: var(--fella-text-secondary);
}

.fella-pagination-icon-svg {
    width: 20px;
    height: 20px;
    color: currentcolor;
    stroke-linejoin: round;
}

.fella-pagination-left:hover .fella-pagination-left-icon, .fella-pagination-right:hover .fella-pagination-right-icon,
.fella-pagination-left:hover .fella-pagination-header, .fella-pagination-right:hover .fella-pagination-header {
    color: var(--fella-text-main);
}

.fella-pagination-left-icon, .fella-pagination-right-icon, .fella-pagination-header {
    transition: color 200ms;
}

/* overviews.css */
.fella-overview {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin-bottom: 35px;
}

.fella-overview-listing {
    display: flex;
    align-items: center;
    gap: 5px;
}

/* navigation.css */
.fella-nav {
    background-color: var(--fella-bg-main);
    box-shadow: 0 1px 0 0 var(--fella-border-dim);
    backdrop-filter: none;
    border: none;
    transition: box-shadow 150ms;
    height: 64px;
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 99;
}

.fella-nav-items {
    display: flex;
}

.fella-nav-inner {
    margin: auto;
    padding-left: 24px;
    padding-right: 24px;
    max-width: 1400px;
    flex: 1;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.fella-nav.fella-nav-no-border {
    box-shadow: none;
}

.fella-nav-icon {
    height: 30px;
    vertical-align: middle;
}

.fella-nav-icon-outer {
    height: 30px;
    display: block;
    padding-right: 5px;
}

.fella-nav-subtitle {
    padding: 8px 12px;
    color: var(--fella-text-tertiary);
    font-size: 14px;
    line-height: 1;
}

.fella-nav-left, .fella-nav-right {
    display: flex;
    align-items: center;
    flex: auto;
    height: 30px;
    gap: 12px;
}

.fella-nav-right {
    justify-content: right;
}

.fella-nav-navigation-item {
    border-radius: 999px;
    padding: 8px 12px;
    color: var(--fella-text-secondary);
    list-style: none;
    cursor: pointer;
    transition: color 150ms ease;
    font-size: 14px;
    line-height: 1;
    height: 14px;
}

.fella-nav-navigation-item:hover {
    color: var(--fella-text-main);
}

.fella-nav-tabs {
    border-radius: 999px;
    background-color: var(--fella-bg-secondary-dim);
    transition: left 150ms ease, width 150ms ease, opacity 150ms ease;
    height: 30px;
    position: fixed;
    width: 16px;
    z-index: -1;
    opacity: 0;
}

.fella-nav-navigation-item-chevron-open, .fella-nav-navigation-item.open .fella-nav-navigation-item-chevron-closed {
    display: none;
}

.fella-nav-navigation-item.open .fella-nav-navigation-item-chevron-open {
    display: inline-block;
}

.fella-nav-navigation-item-chevron-closed, .fella-nav-navigation-item-chevron-open {
    vertical-align: middle;
}

.fella-nav-category-outer {
    padding: 20px;
    position: absolute;
    align-self: end;
    top: 42px;
    pointer-events: none;
    opacity: 0;
    transition: opacity 150ms ease;
}

.fella-nav-category {
    border: 1px solid var(--fella-border-plain);
    border-radius: 12px;
    background-color: var(--fella-bg-main);
}

.fella-nav-category-base {
    display: flex;
    transition: opacity 300ms ease, margin-left 300ms ease, clip-path 300ms ease;
    position: absolute;
    opacity: 0;
    margin-left: 248px;
    pointer-events: none;
}

.fella-nav-category-base.reverse {
    margin-left: -248px;
}

.fella-nav-category-base.reverse.show {
    opacity: 1;
    margin-left: 0;
    pointer-events: auto;
}

.fella-nav-category-base.reverse.hide {
    opacity: 0;
    margin-left: 248px;
}

.fella-nav-category-base.show {
    opacity: 1;
    margin-left: 0;
    pointer-events: auto;
}

.fella-nav-category-base.hide {
    opacity: 0;
    margin-left: -248px;
}

.fella-nav-category-indicator {
    position: fixed;
    transform: rotate(180deg);
    margin-top: -24.5px;
    left: 0;
}

.fella-nav-category-indicator-icon {
    width: 32px;
    height: 32px;
}

.fella-nav-category-section {
    padding: 8px 0;
    width: 248px;
}

.fella-nav-category-section:nth-child(1) {
    padding-left: 8px;
}

.fella-nav-category-section:nth-last-child(1) {
    padding-right: 8px;
}

.fella-nav-category-section-header {
    padding: 8px 12px;
    color: var(--fella-text-secondary);
    font-size: .875rem;
    line-height: 1.25rem;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.fella-nav-category-section-item {
    padding: 12px;
    display: flex;
    gap: 12px;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
}

.fella-nav-category-section-item:hover .fella-nav-category-section-item-icon-container {
    color: var(--fella-bg-main);
    background: var(--fella-text-main);
}

.fella-nav-category-section-item:hover .fella-nav-category-section-item-text-description {
    color: var(--fella-text-main);
}

.fella-nav-category-section-item-icon-container {
    width: 32px;
    height: 32px;
    background: var(--fella-bg-main);
    border: 1px solid var(--fella-border);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--fella-text-secondary);
    transition: color 150ms ease, background 150ms ease;
}

.fella-nav-category-section-item-text-title {
    font-size: 14px;
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    font-weight: 600;
    line-height: 20px;
    white-space: nowrap;
    color: var(--fella-text-main);
}

.fella-nav-category-section-item-text-description {
    font-size: .75rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    line-height: 1rem;
    white-space: nowrap;
    color: var(--fella-text-secondary);
    transition: color 150ms ease;
}

.fella-nav-navigation-item-link {
    color: inherit;
    text-decoration: inherit;
}

.fella-nav-left:has(.fella-nav-navigation-item-with-category:hover):not(.navigating) .fella-nav-category-outer,
.fella-nav-left:has(.fella-nav-category-outer:hover):not(.navigating) .fella-nav-category-outer {
    opacity: 1;
    pointer-events: auto;
}

.fella-nav-mobile {
    display: none;
}

/* navigation-mobile.css */
@media (max-width: 900px) {
    body:has(.fella-nav-mobile-open) {
        overflow: hidden;
    }

    .fella-nav-mobile {
        display: flex;
    }

    .fella-nav-desktop {
        display: flex;
        pointer-events: none;
        overflow-x: auto;
        top: -40px;
        left: 0;
        right: 0;
        bottom: 0;
        height: auto;
        opacity: 0;
        transition: box-shadow 350ms cubic-bezier(.4, 0, .2, 1), top 350ms cubic-bezier(.4, 0, .2, 1), opacity 350ms cubic-bezier(.4, 0, .2, 1);
    }

    .fella-nav-hamburger-container {
        color: hsl(0, 0%, 63%);
    }

    .fella-nav-hamburger-open {
        display: none;
    }

    body:has(.fella-nav.fella-nav-mobile-open) .fella-nav-hamburger-open {
        display: initial;
    }

    body:has(.fella-nav.fella-nav-mobile-open) .fella-nav-hamburger-closed {
        display: none;
    }

    body:has(.fella-nav.fella-nav-mobile-open) .fella-nav-desktop {
        pointer-events: initial;
        top: 64px;
        opacity: 1;
    }

    .fella-nav-desktop .fella-nav-inner {
        margin-top: 10px;
        height: auto;
        display: flex;
        flex-flow: row-reverse wrap-reverse;
        align-items: start;
    }

    .fella-nav-category {
        margin-top: 10px;
        height: auto !important;
        width: 100% !important;
        border-radius: 0;
        border: none;
        background-color: transparent;
    }

    .fella-nav-category-outer {
        position: initial;
        opacity: 1;
        pointer-events: inherit;
        padding: 0;
    }

    .fella-nav-category-base {
        position: initial;
        opacity: 1;
        pointer-events: inherit;
        margin-left: 0;
        width: 100%;
        display: block;
    }

    .fella-nav-desktop .fella-nav-left {
        display: block;
        width: 100%;
        margin: 0 -12px;
    }

    .fella-nav-desktop .fella-nav-right .fella-btn {
        width: 100%;
        height: 40px;
        padding: 0 10px;
    }

    .fella-nav-desktop .fella-nav-right {
        justify-content: center !important;
    }

    .fella-nav-desktop .fella-nav-icon, .fella-nav-desktop .fella-nav-icon-outer {
        display: none;
    }

    .fella-nav-category-indicator {
        display: none;
    }

    .fella-nav-navigation-item {
        flex-basis: 100%;
    }

    .fella-nav-desktop .fella-nav-left, .fella-nav-desktop .fella-nav-right {
        height: auto;
    }

    .fella-nav-desktop .fella-nav-right {
        flex-flow: row-reverse wrap-reverse;
    }

    .fella-nav-category-section, .fella-nav-items {
        padding: 12px 0 !important;
        flex-basis: 100%;
        width: 100%;
    }

    .fella-nav-items {
        flex-flow: row wrap;
        padding: 0;
        margin: 0;
    }

    .fella-nav-category-section-header, .fella-nav-category-section-item-text-title, .fella-nav-navigation-item {
        font-size: initial;
        font-weight: inherit;
    }

    .fella-nav-category-section, .fella-nav-items {
        border-top: 1px solid hsl(0, 0%, 12%);
    }

    .fella-nav-category-section-item, .fella-nav-navigation-item {
        color: hsl(0, 0%, 63%);
    }

    .fella-nav-category-section-item-text-title {
        color: inherit;
        margin-bottom: 0;
    }

    .fella-nav-category-section-item-text-description {
        display: none;
    }

    .fella-nav-category-section-item, .fella-nav-navigation-item {
        padding: 0;
        gap: 8px;
    }

    .fella-nav-category-section-header, .fella-nav-navigation-item {
        padding-left: 0;
        padding-right: 0;
    }

    .fella-nav-navigation-item {
        display: flex;
        align-items: center;
    }

    .fella-nav-navigation-item.fella-nav-navigation-item-with-category {
        display: none;
    }

    .fella-nav-category-section-item-icon-container {
        background-color: transparent;
        border: none;
    }

    .fella-nav-category-base:nth-child(1) .fella-nav-category-section:nth-child(1) {
        border: none;
    }

    .fella-nav-category-section-header, .fella-nav-category-section-item, .fella-nav-navigation-item {
        height: 48px;
        padding: 0 12px;
    }

    .fella-nav-category-section-item:hover .fella-nav-category-section-item-icon-container {
        color: inherit;
        background: initial;
    }

    .fella-nav-category-section-item-icon-container {
        width: max-content;
        height: max-content;
    }

    .fella-nav-category-section-item:hover, .fella-nav-navigation-item:hover {
        color: hsl(0, 0%, 93%);
        background-color: hsl(0, 0%, 10%);
    }

    .fella-nav-category-section-item, .fella-nav-navigation-item {
        transition: color 150ms ease, background-color 150ms ease;
        border-radius: 6px;
    }

    .fella-nav-navigation-item {
        padding: 0;
    }

    .fella-nav-navigation-item-link {
        width: 100%;
        padding: 0 12px;
        height: 48px;
        display: flex;
        align-items: center;
    }
}

/* modal.css */
.fella-modal {
    position: fixed;
    z-index: 999;
    inset: 0;
    background-color: rgba(0, 0, 0, .75);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity .35s cubic-bezier(.4, 0, .2, 1);
}

.fella-modal-dialog {
    width: 540px;
    max-width: 90vw;
    max-height: min(800px, 80vh);
    background-color: #0a0a0a;
    border-radius: 12px;
    box-shadow: 0 0 0 1px hsla(0, 0%, 100%, .1),
    0 1px 1px rgba(0, 0, 0, .02),
    0 8px 16px -4px rgba(0, 0, 0, .04),
    0 24px 32px -8px rgba(0, 0, 0, .06);
    opacity: 0;
    transform: translate3d(0, -40px, 0);
    transition: opacity .35s cubic-bezier(.4, 0, .2, 1), transform .35s cubic-bezier(.4, 0, .2, 1);
}

.fella-modal-body {
    padding: 24px;
    overflow-x: auto;
    max-height: calc(min(800px, 80vh) - 72px);
    background-color: black;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.fella-modal-title {
    color: hsl(0, 0%, 93%);
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: -0.029375rem;
    font-weight: 500;
    text-align: left;
    margin-top: 0;
    margin-bottom: 24px;
}

.fella-modal-footer {
    box-shadow: 0 -1px 0 0 hsla(0, 0%, 100%, .14);
    padding: 16px;
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.fella-modal-footer .fella-btn {
    height: 40px;
    padding: 0 16px;
    display: inline-flex;
}

.fella-modal-footer-left, .fella-modal-footer-right {
    display: flex;
    gap: 10px;
    justify-content: left;
}

.fella-modal-footer-right {
    justify-content: right;
}

.fella-modal.show {
    opacity: 1;
    pointer-events: initial;
}

.fella-modal.show .fella-modal-dialog {
    opacity: 1;
    transform: translateZ(0);
}

body:has(.fella-modal.show) {
    overflow: hidden !important;
}

/* loader.css */
.fella-loader {
    height: 32px;
    width: 32px;
}

.fella-loader-container {
    position: fixed;
    inset: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 64px);
    background-color: var(--fella-bg-main);
}

.fella-loader-container.fella-loader-ajax {
    background-color: transparent;
}

body:not(.fella-loaded):has(.fella-loader-container) {
    overflow: hidden;
}

body.fella-loaded .fella-loader-container {
    display: none;
}

/* lists.css */
.fella-list-item {
    border-bottom: 1px solid var(--fella-border-plain);
    display: block;
}

.fella-list-item:nth-last-child(1) {
    border-bottom: none;
}

.fella-list-item-lg {
    padding: 10px 30px;
}

.fella-list-link {
    cursor: pointer;
    color: inherit;
    text-decoration: inherit;
}

.fella-list-link:hover {
    background-color: var(--fella-list-hover);
}

.fella-list-link:nth-child(1) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.fella-list-link:nth-last-child(1) {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.fella-list-container {
    border: 1px solid var(--fella-border-plain);
    border-radius: 10px;
}

.fella-list-item-padded {
    padding: 8px 16px;
}

/* indications.css */
.fella-indications-container {
    display: flex;
    gap: 22px;
    width: 100%;
    margin: 20px auto;
    align-items: stretch;
    justify-content: stretch;
}

.fella-indications-card {
    flex-basis: 100%;
    align-self: stretch;
}

.fella-indications-image {
    border: 1px solid var(--fella-border-plain);
    aspect-ratio: 16/9;
    background-position: center;
    background-size: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.fella-indications-content {
    border: 1px solid var(--fella-border-plain);
    border-top: none;
    padding: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.fella-indications-caution {
    background-image: linear-gradient(180deg, var(--fella-warning-25) 0%, var(--fella-bg-main) 75px);
}

.fella-indications-dont {
    background-image: linear-gradient(180deg, var(--fella-negative-25) 0%, var(--fella-bg-main) 75px);
}

.fella-indications-do {
    background-image: linear-gradient(180deg, var(--fella-positive-25) 0%, var(--fella-bg-main) 75px);
}

.fella-indications-header {
    margin-top: 0;
}

.fella-indications-dont .fella-indications-header {
    color: var(--fella-negative-100);
}

.fella-indications-caution .fella-indications-header {
    color: var(--fella-warning-100);
}

.fella-indications-do .fella-indications-header {
    color: var(--fella-positive-100);
}

.fella-indications-text {
    margin-bottom: 0;
}

.fella-indications-dont .fella-indications-text {
    color: var(--fella-negative-brighter-1);
}

.fella-indications-caution .fella-indications-text {
    color: var(--fella-warning-brighter-1);
}

.fella-indications-do .fella-indications-text {
    color: var(--fella-positive-brighter-1);
}

/* indications-mobile.css */
@media (max-width: 1100px) {
    .fella-indications-container {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }

    .fella-indications-card {
        display: block;
        width: 292px;
        margin-left: auto;
        margin-right: auto;
    }
}

/* hero.css */
.fella-hero {
    height: calc(100vh - 64px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.fella-hero-inner {
    display: grid;
    grid-template-columns: 1fr max-content;
    width: 100%;
}

.fella-hero-image {
    text-align: center;
}

.fella-hero-text {
    display: flex;
    align-items: center;
}

.fella-hero-headline, .fella-headline {
    color: var(--fella-text-main);
    font-weight: 800;
    letter-spacing: -0.066875rem;
    line-height: 3.5rem;
    font-size: 3rem;
    width: 400px;
}

.fella-hero-title {
    z-index: 2;
    position: absolute;
}

.fella-hero-tagline, .fella-tagline {
    width: 400px;
    margin-top: 30px;
    color: var(--fella-text-secondary);
    line-height: 2rem;
    font-weight: 500;
    letter-spacing: 0;
    font-size: 1.25rem;
    text-wrap: balance;
}

.fella-hero-image-inner {
    width: 40vw;
    height: 40vw;
    margin-left: 35vw;
}

.fella-hero-background-path {
    filter: blur(50px);
    opacity: .5;
    animation-name: hero;
    animation-duration: 10s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    transform: rotate(0deg);
    transform-origin: center;
}

@keyframes fella-hero-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.fella-hero-cta-buttons {
    display: flex;
    gap: 16px;
    align-items: center;
}

.fella-hero-cta-left {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    flex-direction: row;
}

.fella-hero-cta-right {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    flex-direction: row;
}

.fella-hero-cta-orgs {
    color: var(--fella-text-secondary);
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: -.32px;
    flex: 1;
    flex-basis: 100%;
}

.fella-hero-cta, .fella-cta {
    font-weight: inherit;
    letter-spacing: initial;
    margin-top: 30px;
    width: max-content;
}

.fella-hero-cta-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -.96px;
    text-wrap: balance;
    color: var(--fella-text-secondary);
    flex: 1;
    flex-basis: 100%;
}

.fella-hero-cta-title-focus, .fella-hero-cta-orgs-focus {
    font-weight: 700;
    color: var(--fella-text-main);
}

/* hero-mobile.css */
@media (max-width: 1140px) {
    .fella-card-container, .fella-overview {
        grid-template-columns: repeat(2, 1fr) !important;
    }
}

@media (max-width: 900px) {
    .fella-hero-tagline, .fella-tagline {
        width: 100% !important;
    }

    .fella-hero-cta {
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .fella-card-container, .fella-overview {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .fella-hero-inner {
        grid-template-columns: 1fr !important;
        grid-template-rows: 1fr max-content !important;
        height: 100% !important;
    }

    .fella-hero-title {
        position: initial !important;
        text-align: center !important;
    }

    .fella-headline, .fella-hero-headline {
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .fella-hero-text {
        justify-content: center !important;
    }

    .fella-hero-image-inner {
        margin-left: auto !important;
        margin-right: auto !important;
        min-width: 400px !important;
        min-height: 400px !important;
    }

    .fella-card-cta {
        grid-template-columns: 1fr !important;
        margin-top: 20px !important;
    }
}

/* header.css */
.fella-header {
    height: max-content;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 9;
    text-align: center;
    justify-content: center;
    min-height: 48px;
}

.fella-header-btn {
    height: 32px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-left: 10px;
}

/* forms.css */
.fella-form-control {
    font-family: inherit;
    outline: none !important;
    width: 512px;
    max-width: 80vw;
    color: var(--fella-text-main);
}

.fella-form-control.default, .fella-form-control::placeholder {
    color: var(--fella-form-placeholder);
}

input.fella-form-control, input.fella-form-control:hover, input.fella-form-control:focus,
select.fella-form-control, select.fella-form-control:hover, select.fella-form-control:focus {
    padding: 0 18px;
    height: 40px;
    font: inherit;
    font-size: 15px;
    font-weight: 200;
    background-color: var(--fella-bg-secondary);
    border-radius: 6px;
    border: 1px solid transparent;
    box-shadow: 0 0 0 1px var(--fella-border);
    transition: border-color 150ms ease, box-shadow 150ms ease;
}

textarea.fella-form-control, textarea.fella-form-control:hover, textarea.fella-form-control:focus {
    padding: 9px 18px;
    font: inherit;
    font-size: 15px;
    font-weight: 200;
    background-color: var(--fella-bg-secondary);
    border-radius: 6px;
    border: 1px solid transparent;
    box-shadow: 0 0 0 1px var(--fella-border);
    transition: border-color 150ms ease, box-shadow 150ms ease;
}

input.fella-form-control:focus, select.fella-form-control:focus, textarea.fella-form-control:focus {
    border-color: var(--fella-form-border);
    box-shadow: 0 0 0 3px var(--fella-form-outline);
}

select.fella-form-control:hover:not(:focus) {
    box-shadow: 0 0 0 1px var(--fella-border-bold);
}

select.fella-form-control {
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' style='stroke-linecap:round;stroke-linejoin:round;'><path d='M6,9L12,15L18,9' style='stroke:hsl(0,0%,63%);stroke-width:1.5px;'/></svg>");
    background-repeat: no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    background-size: 18px;
    -moz-appearance:none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
}

select.fella-form-control:hover {
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' style='stroke-linecap:round;stroke-linejoin:round;'><path d='M6,9L12,15L18,9' style='stroke:hsl(0,0%,93%);stroke-width:1.5px;'/></svg>");
}

.fella-form-header {
    display: block;
    margin-bottom: 8px;
}

.fella-form label {
    margin-top: 20px;
    display: block;
}

.fella-form-control[disabled], .fella-form-control.disabled {
    opacity: .75;
    pointer-events: none;
}

/* footer.css */
.fella-footnotes {
    color: var(--fella-text-secondary);
    margin-top: 20px;
}

.fella-footer-inner {
    margin: auto;
    padding-left: 24px;
    padding-right: 24px;
    max-width: 1400px;
    display: grid;
    grid-template-columns: 1fr repeat(3, 1.5fr);
    gap: 24px;
    flex-wrap: wrap;
}

.fella-footer {
    padding: 28px 24px 24px;
}

.fella-footer-logo-img {
    width: 24px;
    height: 24px;
}

.fella-footer-brand {
    display: flex;
    padding-top: 8px;
    gap: 10px;
    flex-direction: row;
    flex-wrap: wrap;
}

.fella-footer-brand-copyright, .fella-footer-brand-version {
    color: var(--fella-text-secondary);
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
}

.fella-footer-logo {
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 12px;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.fella-footer-status {
    text-align: left;
    margin-right: auto;
    width: max-content;
    padding: 8px !important;
    margin-left: -8px;
    height: 18px !important;
    box-shadow: none !important;
    display: flex;
    gap: 6px;
    align-items: center;
    flex-direction: row;
    color: var(--fella-neutral-100);
    transition: color 150ms ease, background-color 150ms ease !important;
}

.fella-footer-status:not(:hover) {
    background-color: transparent;
}

.fella-footer-status-indicator {
    width: 10px;
    height: 10px;
    border-radius: 999px;
    background-color: var(--fella-neutral-100);
    display: inline-block;
    transition: background-color 150ms ease;
}

.fella-footer-status-text {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    flex: 1;
}

.fella-footer-status.status-success .fella-footer-status-indicator,
.fella-footer-status-indicator.status-success {
    background-color: var(--fella-positive-100);
}

.fella-footer-status.status-success {
    color: var(--fella-positive-100);
}

.fella-footer-status.status-warning .fella-footer-status-indicator,
.fella-footer-status-indicator.status-warning {
    background-color: var(--fella-warning-100);
}

.fella-footer-status.status-warning {
    color: var(--fella-warning-100);
}

.fella-footer-status.status-error .fella-footer-status-indicator,
.fella-footer-status-indicator.status-error {
    background-color: var(--fella-negative-100);
}

.fella-footer-status.status-error {
    color: var(--fella-negative-100);
}

.fella-footer-socials {
    margin-top: auto;
    margin-bottom: 12px;
    width: 100%;
}

.fella-footer-socials-inner {
    margin: 0;
    padding: 0 0 0 5px;
    list-style: none;
    display: inline-flex;
    align-items: center;
}

.fella-footer-socials-inner li {
    display: inline-flex;
    margin-right: 19px;
}

.fella-footer-socials-inner .fella-footer-social {
    padding: 3px;
    margin: -3px;
    border-radius: 9999px;
    color: var(--fella-text-secondary);
    transition: color 150ms ease;
}

.fella-footer-socials-inner .fella-footer-social:hover {
    color: var(--fella-text-main);
}

.fella-footer-title {
    font-weight: 600;
    font-size: 14px;
    margin: 12px 0;
}

.fella-footer-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.fella-footer-item {
    padding: 6px 0;
    color: var(--fella-text-secondary);
    font-size: 14px;
}

.fella-footer-item a {
    color: inherit;
    text-decoration: none;
    transition: color 150ms ease;
}

.fella-footer-item a:hover {
    color: var(--fella-text-main);
}

.fella-footer-logo .fella-footer-brand-version,
.fella-footer-logo:hover .fella-footer-brand-copyright {
    display: none;
}

.fella-footer-logo:hover .fella-footer-brand-version {
    display: block;
}

/* footer-mobile.css */
@media (max-width: 900px) {
    .fella-footer-inner {
        grid-template-columns: 1fr !important;
    }
}

/* errors.css */
.fella-error {
    display: flex;
    align-items: center;
    justify-content: center;
    inset: 0;
    position: fixed;
    z-index: 9;
    text-align: center;
    background-color: var(--fella-bg-main);
}

.fella-error-icon {
    width: 196px;
    height: 196px;
}

.fella-error-btn {
    margin: 12px auto 24px;
    cursor: pointer;
}

.fella-error-title {
    color: var(--fella-text-main);
    margin: 64px 0 8px;
    font-weight: 800;
    letter-spacing: -0.066875rem;
    line-height: 3.5rem;
    font-size: 3rem;
}

.fella-error-message {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    margin: 12px 0;
    color: var(--fella-text-secondary);
    height: 48px;
}

/* effects.css */
body.fella-visual-effects .fella-nav {
    backdrop-filter: blur(10px);
    background-color: var(--fella-bg-main-transparent);
}

@media (max-width: 900px) {
    body.fella-visual-effects .fella-sidebar {
        backdrop-filter: blur(10px);
        background-color: var(--fella-bg-main-transparent-2);
    }
}

body.fella-visual-effects .fella-modal {
    background-color: var(--fella-bg-main-transparent);
    transition: opacity .35s cubic-bezier(.4,0,.2,1), backdrop-filter .35s cubic-bezier(.4,0,.2,1);
}

body.fella-visual-effects .fella-modal.show {
    backdrop-filter: blur(10px);
}

/* core.css */
@font-face {
    src: url("../font-new.woff2");
    font-family: "Red Hat Text";
    font-weight: 500;
}

html, body {
    margin: 0;
    background-color: var(--fella-bg-main);
    color: var(--fella-text-main);
    font-family: "Red Hat Text", sans-serif;
    text-rendering: optimizeLegibility;
}

blockquote {
    border-left: 2px solid currentColor;
    margin-left: 1rem;
    padding-left: 1rem;
}

.fella-limited-width {
    max-width: 768px;
}

* {
    box-sizing: content-box;
    touch-action: manipulation;
    overflow-wrap: anywhere;
}

.fella-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1080px;
    padding-left: 24px;
    padding-right: 24px;
}

.fella-padded {
    padding-bottom: 90px;
    min-height: 100vh;
}

.fella-escape {
    margin-top: 64px;
}

.fella-section {
    padding-top: 30px;
}

.fella-section a[href].link, .fella-section .fella-section-linked a[href],
.fella-section.fella-section-linked a[href] {
    color: inherit;
}

.fella-section a[href].link:hover, .fella-section .fella-section-linked a[href]:hover,
.fella-section.fella-section-linked a[href]:hover {
    opacity: .75;
}

.fella-text-small {
    font-size: 14px;
    font-weight: 300;
}

/* contact.css */
.fella-contact-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}

.fella-contact-secondary {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    margin-top: 32px;
}

.fella-contact-grid-item {
    padding: 20px;
}

.fella-contact-grid-item-icon {
    width: 52px;
    height: 52px;
}

.fella-contact-grid-item-title {
    margin-top: 20px;
    color: hsl(0 0 93%);
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 700;
    letter-spacing: -1.28px;
}

.fella-contact-grid-item-text {
    color: hsl(0 0 63%);
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 500;
    margin-top: 20px;
}

.fella-contact-grid-item-buttons {
    margin-top: 20px;
    display: flex;
    gap: 16px;
}

.fella-contact-grid-item-button {
    width: max-content;
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.fella-contact-grid-secondary-button {
    width: max-content;
}

.fella-contact-grid-item-secondary {
    margin-top: 20px;
    color: hsl(0 0 63%);
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
}

.fella-contact-grid-item-secondary b {
    font-weight: 700;
    letter-spacing: -0.32px;
    color: hsl(0 0 93%);
}

.fella-contact-title {
    font-size: 3.875rem;
    line-height: 1.25rem;
    font-weight: 900;
}

.fella-contact-status {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* contact-mobile.css */
@media (max-width: 900px) {
    .fella-contact-grid, .fella-contact-secondary {
        grid-template-columns: 1fr !important;
    }

    .fella-contact-grid-item-button.fella-btn-secondary {
        display: none !important;
    }
}

/* colors.css */
:root {
    --fella-bg-main: #000;
    --fella-bg-main-transparent: #00000055;
    --fella-bg-main-transparent-2: #000000cc;
    --fella-bg-secondary: #0a0a0a;
    --fella-bg-secondary-dim: #1f1f1f;
    --fella-text-main: #ededed;
    --fella-text-secondary: #a1a1a1;
    --fella-text-tertiary: #888;
    --fella-text-dim: #ccc;
    --fella-border: #ffffff23;
    --fella-border-bold: #ffffff3d;
    --fella-border-dim: #ffffff19;
    --fella-border-vercel: #5e5e5e;
    --fella-border-plain: #2e2e2e;

    --fella-list-hover: #ffffff0c;
    --fella-form-placeholder: #8f8f8f7f;
    --fella-form-outline: #3d3d3d;
    --fella-form-border: #a0a0a0;

    --fella-badge-notice-rgb: 82,168,255;

    --fella-positive-rgb: 0, 114, 245;
    --fella-positive-0: rgba(var(--fella-positive-rgb), 0);
    --fella-positive-25: rgba(var(--fella-positive-rgb), .25);
    --fella-positive-50: rgba(var(--fella-positive-rgb), .5);
    --fella-positive-75: rgba(var(--fella-positive-rgb), .75);
    --fella-positive-100: rgb(var(--fella-positive-rgb));
    --fella-positive-brighter-1-rgb: 61, 147, 245;
    --fella-positive-brighter-1: rgb(var(--fella-positive-brighter-1-rgb));
    --fella-positive-brighter-2-rgb: 122, 180, 245;
    --fella-positive-brighter-2: rgb(var(--fella-positive-brighter-2-rgb));
    --fella-positive-brighter-2-33: rgb(var(--fella-positive-brighter-2-rgb), .33);
    --fella-positive-brighter-2-0: rgb(var(--fella-positive-brighter-2-rgb), 0);

    --fella-warning-rgb: 255, 178, 36;
    --fella-warning-0: rgba(var(--fella-warning-rgb), 0);
    --fella-warning-25: rgba(var(--fella-warning-rgb), .25);
    --fella-warning-50: rgba(var(--fella-warning-rgb), .5);
    --fella-warning-75: rgba(var(--fella-warning-rgb), .75);
    --fella-warning-100: rgb(var(--fella-warning-rgb));
    --fella-warning-brighter-1-rgb: 255, 201, 99;
    --fella-warning-brighter-1: rgb(var(--fella-warning-brighter-1-rgb));
    --fella-warning-brighter-2-rgb: 255, 223, 163;
    --fella-warning-brighter-2: rgb(var(--fella-warning-brighter-2-rgb));
    --fella-warning-brighter-2-33: rgb(var(--fella-warning-brighter-2-rgb), .33);
    --fella-warning-brighter-2-0: rgb(var(--fella-warning-brighter-2-rgb), 0);

    --fella-negative-rgb: 229, 72, 77;
    --fella-negative-0: rgba(var(--fella-negative-rgb), 0);
    --fella-negative-25: rgba(var(--fella-negative-rgb), .25);
    --fella-negative-50: rgba(var(--fella-negative-rgb), .5);
    --fella-negative-75: rgba(var(--fella-negative-rgb), .75);
    --fella-negative-100: rgb(var(--fella-negative-rgb));
    --fella-negative-brighter-1-rgb: 230, 129, 132;
    --fella-negative-brighter-1: rgb(var(--fella-negative-brighter-1-rgb));
    --fella-negative-brighter-2-rgb: 230, 186, 187;
    --fella-negative-brighter-2: rgb(var(--fella-negative-brighter-2-rgb));
    --fella-negative-brighter-2-33: rgb(var(--fella-negative-brighter-2-rgb), .33);
    --fella-negative-brighter-2-0: rgb(var(--fella-negative-brighter-2-rgb), 0);

    --fella-neutral-rgb: 142, 142, 142;
    --fella-neutral-0: rgba(var(--fella-neutral-rgb), 0);
    --fella-neutral-25: rgba(var(--fella-neutral-rgb), .25);
    --fella-neutral-50: rgba(var(--fella-neutral-rgb), .5);
    --fella-neutral-75: rgba(var(--fella-neutral-rgb), .75);
    --fella-neutral-100: rgb(var(--fella-neutral-rgb));
    --fella-neutral-brighter-1-rgb: 206, 206, 206;
    --fella-neutral-brighter-1: rgb(var(--fella-neutral-brighter-1-rgb));
    --fella-neutral-brighter-2-rgb: 255, 255, 255;
    --fella-neutral-brighter-2: rgb(var(--fella-neutral-brighter-2-rgb));
    --fella-neutral-brighter-2-33: rgb(var(--fella-neutral-brighter-2-rgb), .33);
    --fella-neutral-brighter-2-0: rgb(var(--fella-neutral-brighter-2-rgb), 0);

    --fella-theme: rgb(63, 111, 116);
    --fella-theme-rgb: 63, 111, 116;
    --fella-theme-0: rgba(var(--fella-theme-rgb), 0);
    --fella-theme-25: rgba(var(--fella-theme-rgb), .25);
    --fella-theme-50: rgba(var(--fella-theme-rgb), .5);
    --fella-theme-75: rgba(var(--fella-theme-rgb), .75);
    --fella-theme-100: rgb(var(--fella-theme-rgb));
}

/* code.css */
.fella-code-block .line {
    padding: 0 20px;
}

.fella-code-block {
    counter-reset: line;
    border-radius: 6px;
    background-color: var(--fella-bg-secondary);
    padding: 20px 0;
    border: 1px solid var(--fella-border-plain);
    margin: 20px 0;
    overflow-y: scroll;
}

.fella-code-block .line::before {
    content: counter(line) /"";
    counter-increment: line;
    width: 16px;
    font-size: 13px;
    color: var(--fella-text-tertiary);
    font-family: Iosevka, monospace;
    text-align: right;
    margin-right: 20px;
    display: inline-block;
}

.fella-code-block .line {
    line-height: 20px;
}

.hljs-keyword {
    color: rgb(249, 117, 131);
}

.hljs-name, .hljs-number {
    color: rgb(121, 184, 255);
}

.hljs-title.class_ {
    color: rgb(133, 232, 157);
}

.hljs-attr, .hljs-variable:not(.constant_), .hljs-property, .hljs-title.function_ {
    color: rgb(179, 146, 240);
}

.hljs-string {
    color: rgb(158, 203, 255);
}

.fella-code-block-titled {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 0;
    margin-top: 0;
}

.fella-code-block-header {
    margin-top: 20px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid var(--fella-border-plain);
    padding: 0 12px 0 16px;
    background-color: var(--fella-bg-main);
    display: flex;
    height: 48px;
    align-items: center;
    color: var(--fella-text-secondary);
    font-size: 13px;
}

.fella-code-block-multilanguage .fella-code-block-language:not(.fella-code-block-selected), .fella-code-block-multilanguage .fella-code-block-filename:not(.fella-code-block-selected) {
    display: none;
}

.fella-code-block-header {
    display: grid;
    grid-template-columns: 1fr max-content;
}

.fella-code-block-selector {
    border: none;
    border-radius: 5px;
    font-size: 13px;
    display: flex;
    padding: 0 30px 0 8px;
    align-items: center;
    line-height: 1;
    transition: background-color .2s,color .2s;
    cursor: pointer;
    color: var(--fella-text-secondary);
    white-space: nowrap;
    background-color: var(--fella-bg-main);
    height: 32px;
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' style='stroke-linecap:round;stroke-linejoin:round;'><path d='M6,9L12,15L18,9' style='fill:transparent;stroke:hsl(0,0%,63%);stroke-width:1.5px;'/></svg>");
    background-repeat: no-repeat;
    background-position: calc(100% - 8px) center !important;
    background-size: 18px;
    -moz-appearance:none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
}

.fella-code-block-selector:hover, .fella-code-block-selector:focus {
    background-color: var(--fella-bg-secondary-dim);
}

.fella-code-block-copy {
    float: right;
    margin-right: 16px;
    margin-top: -70px;
    background-color: var(--fella-bg-main);
    border-radius: 5px;
    transition: opacity .1s,background-color .2s;
    border: 1px solid var(--fella-border-bold);
    height: 32px;
    width: 32px;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--fella-text-secondary);
    opacity: 0;
}

.fella-code-block-language:hover .fella-code-block-copy {
    opacity: 1;
}

.fella-code-block-copy svg {
    width: 16px;
    height: 16px;
}

.fella-code-block-copy:hover {
    background-color: var(--fella-bg-secondary-dim);
}

.fella-code-block-copy-container {
    position: absolute;
}

/* cards.css */
.fella-card {
    border: 1px solid var(--fella-border-plain);
    background-color: transparent;
    border-radius: 10px;
    text-decoration: inherit;
    color: inherit;
}

.fella-card-header {
    color: hsl(0, 0%, 63%);
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1;
    flex-basis: 100%;
}

.fella-card-content {
    flex: 1;
    flex-basis: 100%;
    color: hsl(0, 0%, 93%);
    font-size: 20px;
    line-height: 130%;
    letter-spacing: -.4px;
    font-weight: 700;
    text-wrap: balance;
}

.fella-card-content-small {
    font-size: 1rem;
}

.fella-card-animated {
    transition: transform 150ms ease;
}

.fella-card-animated:hover {
    transform: scale(1.05);
}

.fella-card-bg {
    position: absolute;
    border-radius: 10px;
    opacity: 0;
    background-image: radial-gradient(circle, rgba(37,37,37,1) 0%, rgba(0,0,0,1) 40%);
    background-size: 700px 700px;
    background-position: -700px -700px;
    background-repeat: no-repeat;
    transition: opacity 150ms ease;
    z-index: 2;
    margin: -20px;
}

.fella-card-inner {
    z-index: 5;
    text-decoration: inherit;
    color: inherit;
    gap: 16px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: start;
}

.fella-card-xl {
    padding: 48px;
    margin-top: 50px;
}

.fella-card-cta {
    display: grid;
    grid-template-columns: 2.5fr 1fr;
    grid-gap: 80px;
}

.fella-card-lg {
    padding: 20px 30px;
}

.fella-card-sm {
    padding: 20px;
}

.fella-card-modern {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: start;
    gap: 16px;
}

.fella-card-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
}

/* buttons.css */
.fella-btn-secondary {
    box-shadow: 0 0 0 1px var(--fella-border);
    color: var(--fella-text-main);
    background-color: var(--fella-bg-secondary);
}

.fella-btn {
    transition-property: border-color, background-color, color, transform, box-shadow;
    transition-duration: .15s;
    transition-timing-function: ease;
    border-radius: 6px;
    text-decoration: none;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 32px;
    padding-left: 12px;
    padding-right: 12px;
    cursor: pointer;
}

.fella-btn-large {
    padding-left: 20px;
    padding-right: 20px;
    height: 48px;
    font-size: 16px;
}

.fella-btn-flush {
    width: max-content;
    margin-left: auto;
    margin-right: auto;
}

.fella-btn-round {
    border-radius: 9999px;
    width: 24px;
    padding-left: 4px;
    padding-right: 4px;
}

.fella-btn-secondary:hover {
    background-color: var(--fella-bg-secondary-dim);
}

.fella-btn-primary {
    background-color: var(--fella-text-main);
    color: var(--fella-bg-secondary);
}

.fella-btn-primary:hover {
    background-color: var(--fella-text-dim);
}

.fella-btn-cta {
    padding-left: 14px;
    padding-right: 14px;
    border-radius: 999px;
    height: 48px;
    font-size: 16px;
}

.fella-btn-cta-small {
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 999px;
    height: 40px;
    font-size: .875rem;
}

.fella-btn-cta-text {
    padding-left: 6px;
    padding-right: 6px;
    word-wrap: unset;
    word-break: manual;
    white-space: nowrap;
}

.fella-btn-cta-icon {
    margin-right: 2px;
    width: 20px;
}

.fella-btn[disabled], .fella-btn.disabled {
    opacity: .75;
    pointer-events: none;
}

/* banner.css */
.fella-banner {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 48px;
    background-image: var(--fella-theme);
    position: relative;
    overflow: hidden;
}

.fella-banner > .fella-container {
    padding: 40px 80px;
}

.fella-banner-title {
    margin-bottom: 12px;
    margin-top: 0;
    font-size: 40px;
    line-height: 44px;
}

.fella-banner-subtitle {
    font-weight: normal;
    font-size: 21px;
    color: var(--fella-text-secondary);
    margin-bottom: 15px;
    margin-top: 0;
    line-height: 25px;
}

.fella-banner-tagline {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 21px;
    line-height: 29px;
}

.fella-banner-descriptive {
    margin-bottom: 0;
    margin-top: 15px;
    font-weight: lighter;
    font-size: 14px;
    line-height: 20px;
}

.fella-banner::before {
    content: "";
    background: linear-gradient(var(--fella-theme), color-mix(in srgb, var(--fella-theme), transparent 70%));
    position: absolute;
    width: 100%;
    height: 100%;
}

.fella-banner::after {
    background: var(--fella-bg-main);
    opacity: .55;
    width: 100%;
    position: absolute;
    content: "";
    height: 100%;
    left: 0;
    top: 0;
}

.fella-banner-icon-container {
    position: absolute;
    margin-top: 10px;
    margin-right: 25px;
    right: 0;
    width: 250px;
    height: calc(100% - 20px);
}

.fella-banner-icon-inner {
    mix-blend-mode: screen;
    opacity: .7;
    display: block;
    width: 250px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    max-height: 100%;
}

.fella-banner-icon {
    width: 100%;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    fill: var(--fella-theme);
    transform: scale(1.2);
    overflow: visible;
}

.fella-banner-content {
    position: relative;
    z-index: 1;
}

/* badges.css */
.fella-badge-label {
    color: var(--fella-text-tertiary);
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
}

.fella-badge {
    filter: none !important;
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 4px 8px;
    border: 1px solid var(--fella-border-plain);
    border-radius: 3px;
}

.fella-badge-notice {
    font-size: 11px;
    height: 20px;
    padding: 0 6px;
    border-radius: 999px;
    background-color: rgba(var(--fella-badge-notice-rgb), .25);
    color: rgb(var(--fella-badge-notice-rgb));
    display: inline-flex;
    align-items: center;
}

.fella-badge-icon {
    filter: contrast(0%) brightness(0%) invert(1) !important;
    opacity: 0.533;
    width: 20px;
    height: 20px;
    padding: 2px;
}

